import {IFeedSectionProps} from "./feed-section.types";
import {FunctionComponent} from "react";
import {Section, RichText} from "components";
import {Grid, Container, Card, CardContent, Typography, makeStyles} from "@material-ui/core";
import useAxios from "axios-hooks";
import {getFeedList, IFeed} from "api";
import {LoadingView, ErrorView} from "views";
import MomentUtils from "@date-io/moment";

const useStyles = makeStyles((theme) => ({
    card: {
        borderTop: `5px solid ${theme.palette.primary.main}`,
        transition: theme.transitions.create(['box-shadow', 'transform']),
        '&:hover': {
            transform: 'translate(0, -5px)',
            boxShadow: theme.shadows[4]
        }
    },
}));

export const FeedSection: FunctionComponent<IFeedSectionProps> = (props) => {

    const {section} = props;
    const [{data: feed, loading: feedLoading, error: feedError}] = useAxios<IFeed[]>(getFeedList());
    const classes = useStyles();
    const moment = new MomentUtils();

    if (feedError) {
        return (
            <ErrorView/>
        );
    } else if (feedLoading) {
        return (
            <LoadingView/>
        );
    } else {
        return (
            <Section background={section.background} spacing={8}>
                <Container>
                    <Grid container justify="center" spacing={4}>
                        {feed?.map(
                            (item, key) => (
                                <Grid item md={3} key={key}>
                                    <Card variant="outlined" className={classes.card}>
                                        <CardContent>
                                            <Typography variant="caption" color="textSecondary" component="div" gutterBottom>
                                                {moment.format(moment.date(item.published_at), "DD.MM.yyyy")}
                                            </Typography>
                                            <Typography variant="body1" component="div">
                                                <RichText content={item.content}/>
                                            </Typography>
                                        </CardContent>
                                    </Card>
                                </Grid>
                            )
                        )}
                    </Grid>
                </Container>
            </Section>
        );
    }

}
