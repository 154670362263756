import {FunctionComponent} from 'react';
import {IFormSectionProps} from './form-section.types';
import {PrescriptionForm, Section, ContactForm} from 'components';
import {IForm} from 'api';
import {Container, Card, CardContent, makeStyles, CardHeader, Divider} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    card: {
        borderTop: `5px solid ${theme.palette.primary.main}`
    }
}));

export const FormSection: FunctionComponent<IFormSectionProps> = (props) => {

    const {section} = props;
    const classes = useStyles();

    return (
        <Section background={section.background} spacing={8}>
            <Container maxWidth="md">
                <Card variant="outlined" className={classes.card}>
                    {section.title &&
                    <>
                        <CardHeader title={section.title} titleTypographyProps={{variant: "h5", color: "primary", align: "center"}}/>
                        <Divider/>
                    </>
                    }
                    <CardContent>
                        {getForm(section.form)}
                    </CardContent>
                </Card>
            </Container>
        </Section>
    );
}

function getForm(form: IForm) {
    switch (form.value) {
        case "contact":
            return (<ContactForm/>);
        case "prescription":
            return (<PrescriptionForm/>)
    }
}

