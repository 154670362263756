import React, {FunctionComponent} from "react";
import {Section} from "components";
import {Container, Typography} from "@material-ui/core";
import {IErrorProps} from "./error.types";

export const ErrorView: FunctionComponent<IErrorProps> = (props) => {

    return (
        <Section spacing={16}>
            <Container>
                <Typography component="div" align="center">
                    <Typography variant="h4" gutterBottom color="primary">Something went wrong!</Typography>
                </Typography>
            </Container>
        </Section>
    );
}
