import {FunctionComponent, useContext, useEffect} from "react";
import {IContactFormProps} from "./contact-form.types";
import useAxios from "axios-hooks";
import {IConfiguration, IContactForm, IErrorResponse, ILink, insertContact, IPrescriptionForm, ITranslation} from "api";
import {useForm} from "react-hook-form";
import {ConfContext, TransContext} from "app";
import {makeStyles} from "@material-ui/core/styles";
import {Button, Grid, Typography} from "@material-ui/core";
import {ButtonLink, CheckboxInput, TextInput} from "components";
import {ObjectTyped} from "lib";

const useStyles = makeStyles((theme) => ({
    field: {
        padding: theme.spacing(2),
    }
}));

export const ContactForm: FunctionComponent<IContactFormProps> = (props) => {

    const [{error: contactError, response: contactResponse, loading: contactLoading}, sendSubmit] =
        useAxios<any, IErrorResponse<IContactForm>>(insertContact(), {manual: true});
    const {handleSubmit, control, setError} =
        useForm<IPrescriptionForm>({reValidateMode: "onChange"});
    const classes = useStyles();
    const trans = useContext<ITranslation>(TransContext).values;
    const configuration = useContext<IConfiguration>(ConfContext);

    const gdprLink: ILink = {
        internalUrl: configuration.gdpr,
        color: { value: "primary" },
        type: { value: "link" },
        title: trans.gdprConsent
    }

    useEffect(() => {
        if (contactError) {
            contactError.response?.data?.errors?.forEach((error) => {
                setError(error.property, {
                    type: "manual",
                    message: ObjectTyped.keys(error.constraints!).map((key) => trans.constraints[key]).join(', ')
                });
            });
        }
    }, [contactError, setError, trans]);

    const onSubmit = (data: IPrescriptionForm) => {
        sendSubmit({
            data: data
        });
    };

    if (contactResponse?.status === 200) {
        return (
            <Typography variant="h5">{trans.success}</Typography>
        );
    } else {
        return (
            <form onSubmit={handleSubmit(onSubmit)}>
                <Grid container>
                    <Grid item xs={12} md={6} className={classes.field}>
                        <TextInput title={trans.fullName} name="fullName" control={control} placeholder="Jan Novák"/>
                    </Grid>
                    <Grid item xs={12} md={6} className={classes.field}>
                        <TextInput title={trans.email} name="email" control={control} placeholder="jan@novak.cz"/>
                    </Grid>
                </Grid>

                <Grid container>
                    <Grid item xs={12} className={classes.field}>
                        <TextInput title={trans.message} name={"note"} control={control} rows={6} placeholder="Obsah zprávy"/>
                    </Grid>
                </Grid>

                <Grid container>
                    <Grid item xs={12} className={classes.field}>
                        <CheckboxInput
                            title={<ButtonLink link={gdprLink} newTab={true} />}
                            name="gdprConsent"
                            control={control}
                        />
                    </Grid>
                </Grid>

                <Grid container justify="center">
                    <Grid item xs={6} className={classes.field}>
                        <Button
                            fullWidth
                            variant="contained"
                            color="primary"
                            type="submit"
                            disabled={contactLoading}
                        >
                            {trans.submit}
                        </Button>
                    </Grid>
                </Grid>
            </form>
        );
    }
}
