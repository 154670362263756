import {BrowserRouter as Router, Switch, Route, useLocation} from 'react-router-dom';
import React, {FunctionComponent, useEffect} from 'react';
import {LoadingView, NotFoundView, PageView, ErrorView} from "views";
import {Footer, Header, Metadata} from 'components';
import {CssBaseline, responsiveFontSizes, makeStyles} from '@material-ui/core';
import {createMuiTheme, ThemeProvider} from '@material-ui/core/styles';
import {MuiPickersUtilsProvider} from '@material-ui/pickers';
import {HelmetProvider} from "react-helmet-async";
import {
    getConfiguration,
    getNavigation,
    getPageList,
    getTranslation,
    IConfiguration,
    INavigation,
    IPage,
    ITranslation
} from 'api';
import useAxios from 'axios-hooks';
import MomentUtils from '@date-io/moment';
import moment from "moment";
import 'moment/locale/cs';

const theme = createMuiTheme({
    typography: {
        fontFamily: `"Libre Franklin", "Helvetica", "Arial", sans-serif`,
        h5: {
            fontWeight: 600,
            fontSize: '1.25rem'
        },
        h4: {
            fontWeight: 600,
        },
        h3: {
            fontWeight: 700,
        },
        h1: {
            fontSize: '4rem',
            fontWeight: 400,
        },
        subtitle1: {
            fontSize: '1.2rem',
        },
        subtitle2: {
            fontSize: '1.1rem',
        },
        button: {
            fontWeight: 600,
        },
    },
    palette: {
        primary: {
            main: '#1751b5',
            light: '#f1f6fd'
        },
        secondary: {
            main: '#FFCA28',
            light: '#fff6d9'
        },
        text: {
            secondary: '#718096'
        },
        background: {
            default: '#fff',
        }
    }
});

const useStyles = makeStyles((theme) => ({
    flexColumn: {
        display: "flex",
        flexFlow: "column",
        minHeight: "100vh"
    },
    flexGrow: {
        flexGrow: 1
    }
}));

moment.locale('cs');

export const ConfContext = React.createContext<IConfiguration>(undefined as any);
export const TransContext = React.createContext<ITranslation>(undefined as any);

export const ScrollToTop: FunctionComponent = (props) => {
    const {pathname} = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return (<>{props.children}</>);
}

export const App: FunctionComponent = () => {

    const [{data: navigation, loading: navigationLoading, error: navigationError}] = useAxios<INavigation>(getNavigation());
    const [{data: configuration, loading: configurationLoading, error: configurationError}] = useAxios<IConfiguration>(getConfiguration());
    const [{data: pages, loading: pagesLoading, error: pagesError}] = useAxios<IPage[]>(getPageList());
    const [{data: trans, loading: transLoading, error: transError}] = useAxios<ITranslation>(getTranslation());

    const responsiveTheme = responsiveFontSizes(theme);
    const classes = useStyles();

    if (navigationError || configurationError || pagesError || transError) {
        return (
            <ErrorView/>
        );
    } else if (navigationLoading || configurationLoading || pagesLoading || transLoading) {
        return (
            <LoadingView/>
        );
    } else if (!navigation || !configuration || !pages || !trans) {
        return (
            <ErrorView/>
        );
    } else {
        return (
            <Router>
                <HelmetProvider>
                    <ThemeProvider theme={responsiveTheme}>
                        <MuiPickersUtilsProvider utils={MomentUtils} locale="cs">
                            <ConfContext.Provider value={configuration}>
                                <TransContext.Provider value={trans}>
                                    <CssBaseline/>
                                    <Metadata metadata={configuration.metadata} configuration={configuration}/>

                                    <div className={classes.flexColumn}>
                                        <header>
                                            <Header navigation={navigation} configuration={configuration}/>
                                        </header>

                                        <div className={classes.flexGrow}>
                                            <Switch>
                                                <Route exact path={(pages.map(route => "/" + route.slug).concat("/"))}>
                                                    <ScrollToTop>
                                                        <PageView configuration={configuration}/>
                                                    </ScrollToTop>
                                                </Route>
                                                <Route>
                                                    <ScrollToTop>
                                                        <NotFoundView configuration={configuration}/>
                                                    </ScrollToTop>
                                                </Route>
                                            </Switch>
                                        </div>

                                        <footer>
                                            <Footer navigation={navigation} configuration={configuration}/>
                                        </footer>
                                    </div>
                                </TransContext.Provider>
                            </ConfContext.Provider>
                        </MuiPickersUtilsProvider>
                    </ThemeProvider>
                </HelmetProvider>
            </Router>
        );
    }
}
