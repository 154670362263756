import { AxiosRequestConfig } from "axios";
import { apiRoutes } from "config";

export const getPageList = ():AxiosRequestConfig => {
    return {
        url: apiRoutes.cms.page + "/slug/",
    }
};

export const getPageBySlug = (slug?: string):AxiosRequestConfig => {
    return {
        url: apiRoutes.cms.page + "/slug/" + slug,
    }
};
