import {FunctionComponent} from "react";
import {INotFoundProps} from "./not-found.types";
import {ButtonLink, Section} from "components";
import {Container, Typography} from "@material-ui/core";
import {ILink} from "api";

export const NotFoundView: FunctionComponent<INotFoundProps> = (props) => {

    const { configuration } = props;

    const homepageLink: ILink = {
        internalUrl: configuration.homepage,
        color: { value: "primary" },
        type: { value: "contained" },
        title: 'Homepage'
    }

    return (
        <Section spacing={16}>
            <Container>
                <Typography component="div" align="center">
                    <Typography variant="h4" gutterBottom color="primary">Page not found!</Typography>
                    <Typography variant="subtitle1" gutterBottom color="textSecondary">Please return to homepage</Typography>

                    <ButtonLink link={homepageLink} />
                </Typography>
            </Container>
        </Section>
    );
}
