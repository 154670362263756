import {FunctionComponent, useContext, useEffect} from 'react';
import {IPrescriptionFormProps} from './prescription-form.types';
import {Button, Grid, Divider, Typography, IconButton, Icon} from '@material-ui/core';
import {IConfiguration, IErrorResponse, ILink, insertPrescription, IPrescriptionForm, ITranslation} from 'api';
import useAxios from "axios-hooks";
import {useFieldArray, useForm} from "react-hook-form";
import {makeStyles} from "@material-ui/core/styles";
import {ButtonLink, CheckboxInput, DatetimeInput, NumberInput, SelectInput, TextInput} from "components";
import {ConfContext, TransContext} from "app";
import {ObjectTyped} from "lib";

const useStyles = makeStyles((theme) => ({
    field: {
        padding: theme.spacing(2),
    },
    remove: {
        display: "flex",
        alignItems: "flex-end",
        justifyContent: "center"
    },
    order: {
        order: 3,
        [theme.breakpoints.up('md')]: {
            order: 'initial'
        }
    }
}));

export const PrescriptionForm: FunctionComponent<IPrescriptionFormProps> = (props) => {

    const [{error: prescriptionError, response: prescriptionResponse, loading: prescriptionLoading}, sendSubmit] =
        useAxios<any, IErrorResponse<IPrescriptionForm>>(insertPrescription(), {manual: true});
    const {unregister, handleSubmit, watch, control, setError, clearErrors} =
        useForm<IPrescriptionForm>({defaultValues: {medications: [{}]}, reValidateMode: "onChange"});
    const {fields, append, remove} = useFieldArray({control, name: "medications"});
    const classes = useStyles();
    const deliveryMethod = watch("deliveryMethod");
    const trans = useContext<ITranslation>(TransContext).values;
    const configuration = useContext<IConfiguration>(ConfContext);

    const gdprLink: ILink = {
        internalUrl: configuration.gdpr,
        color: { value: "primary" },
        type: { value: "link" },
        title: trans.gdprConsent
    }

    useEffect(() => {
        if (deliveryMethod) {
            if (deliveryMethod === "PICKUP") {
                unregister("phone");
                unregister("email");
            } else if (deliveryMethod === "SMS") {
                unregister("email");
            } else if (deliveryMethod === "EMAIL") {
                unregister("phone");
            }
        }
    }, [deliveryMethod, unregister]);

    useEffect(() => {
        if (prescriptionError) {
            prescriptionError.response?.data?.errors?.forEach((error) => {
                if (error.children.length > 0 && error.property === "medications") {
                    error.children.forEach((arrayItem) => {
                        arrayItem.children.forEach((nestedItem) => {
                            // @ts-ignore
                            setError(`${error.property}.${arrayItem.property}.${nestedItem.property}` as const, {
                                type: "manual",
                                message: ObjectTyped.keys(nestedItem.constraints!).map((key) => trans.constraints[key]).join(', ')
                            });
                        })
                    })
                } else {
                    setError(error.property, {
                        type: "manual",
                        message: ObjectTyped.keys(error.constraints!).map((key) => trans.constraints[key]).join(', ')
                    });
                }
            });
        }
    }, [prescriptionError, setError, trans]);

    const onSubmit = (data: IPrescriptionForm) => {
        sendSubmit({
            data: data
        });
    };

    if (prescriptionResponse?.status === 200) {
        return (
            <Typography variant="h5">{trans.success}</Typography>
        );
    } else {
        return (
            <form onSubmit={handleSubmit(onSubmit)}>
                <Grid container>
                    <Grid item xs={12} md={6} className={classes.field}>
                        <TextInput title={trans.fullName} name="fullName" control={control} placeholder="Jan Novák"/>
                    </Grid>
                    <Grid item xs={12} md={6} className={classes.field}>
                        <DatetimeInput title={trans.birthday} name="birthday" control={control}
                                       placeholder="07.02.1985"/>
                    </Grid>
                </Grid>

                <Grid container>
                    <Grid item xs={12} md={6} className={classes.field}>
                        <SelectInput
                            title={trans.deliveryMethod}
                            name="deliveryMethod"
                            control={control}
                            values={[
                                {
                                    value: "PICKUP",
                                    label: trans.deliveryMethods.pickup
                                },
                                {
                                    value: "SMS",
                                    label: trans.deliveryMethods.sms
                                },
                                {
                                    value: "EMAIL",
                                    label: trans.deliveryMethods.email
                                },
                            ]}
                        />
                    </Grid>
                    <Grid item xs={12} md={6} className={classes.field}>
                        {deliveryMethod === "SMS" &&
                        <TextInput title={trans.phone} name="phone" control={control} placeholder="700 800 900"/>
                        }

                        {deliveryMethod === "EMAIL" &&
                        <TextInput title={trans.email} name="email" control={control} placeholder="jan@novak.cz"/>
                        }
                    </Grid>
                </Grid>

                <Divider/>

                {fields.map((field, index) => {
                    return (
                        <Grid container key={index}>
                            <Grid item xs={10} md={3} className={classes.field}>
                                <NumberInput title={trans.medicationCount} name={`medications.${index}.count`}
                                             control={control}
                                             placeholder="2"/>
                            </Grid>
                            <Grid item xs={10} md={8} className={`${classes.field} ${classes.order}`}>
                                <TextInput title={trans.medication} name={`medications.${index}.name`} control={control}
                                           placeholder="Ibalgin 400mg 100 tablet"/>
                            </Grid>
                            <Grid item xs={2} md={1} className={`${classes.field} ${classes.remove}`}>
                                {index !== 0 &&
                                <IconButton
                                    aria-label="delete"
                                    color="primary"
                                    onClick={(e) => {
                                        remove(index)
                                    }}
                                >
                                    <Icon color="primary">delete</Icon>
                                </IconButton>
                                }
                            </Grid>
                        </Grid>
                    )
                })}

                <Grid container>
                    <Grid item xs={12} className={classes.field}>
                        <Typography component="div" align="center">
                            <IconButton
                                aria-label="add"
                                color="primary"
                                onClick={(e) => {
                                    append({});
                                    clearErrors("medications");
                                }}
                            >
                                <Icon color="primary" fontSize="large">add</Icon>
                            </IconButton>
                        </Typography>
                    </Grid>
                </Grid>

                <Grid container>
                    <Grid item xs={12} className={classes.field}>
                        <TextInput title={trans.note} name={"note"} control={control} rows={4} optional={true} placeholder="Poznámka"/>
                    </Grid>
                </Grid>

                <Grid container>
                    <Grid item xs={12} className={classes.field}>
                        <CheckboxInput
                            title={<ButtonLink link={gdprLink} newTab={true} />}
                            name="gdprConsent"
                            control={control}
                        />
                    </Grid>
                </Grid>

                <Grid container justify="center">
                    <Grid item xs={6} className={classes.field}>
                        <Button
                            fullWidth
                            variant="contained"
                            color="primary"
                            type="submit"
                            disabled={prescriptionLoading}
                        >
                            {trans.submit}
                        </Button>
                    </Grid>
                </Grid>
            </form>
        );
    }
}
