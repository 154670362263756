import {IImage, IFile, ILink, IConfiguration} from "api";
import {apiRoutes} from "config";
import axios from "axios";
import fileDownload from 'js-file-download';

export const getImageUrl = (image: IImage, format?: "small" | "medium" | "large" | "thumbnail"): string => {
    if (format && image.formats[format]) {
        return apiRoutes.cms.root + image.formats[format].url;
    } else {
        return apiRoutes.cms.root + image.url;
    }
}

export const getFileUrl = (file: IFile): string => {
    return apiRoutes.cms.root + file.url;
}

export const downloadFile = (file: IFile) => {
    axios.get(getFileUrl(file), {
        responseType: 'blob',
    }).then(res => {
        fileDownload(res.data, file.name);
    });
}

export const getUrl = (link: ILink, configuration: IConfiguration): string => {
    const url = link.internalUrl ? link.internalUrl.slug : link.externalUrl;
    return url && url !== configuration.homepage.slug ? url : "/";
}

export const getColumnCount = <T>(items: Array<T>) => {
    switch (items.length) {
        case 1:
            return 12;
        case 2:
            return 6;
        case 3:
            return 4;
        case 4:
            return 3;
        case 6:
            return 2;
        default:
            return 12;
    }
};

export const ObjectTyped = {
    /**
     * Object.keys, but with nice typing (`Array<keyof T>`)
     */
    keys: Object.keys as <T extends {}>(yourObject: T) => Array<keyof T>,
    /**
     * Object.values, but with nice typing
     */
    values: Object.values as <T extends {}>(yourObject: T) => Array<T[keyof T]>,
    /**
     * Object.entries, but with nice typing
     */
    entries: Object.entries as <T extends {}>(
        yourObject: T
    ) => Array<[keyof T, T[keyof T]]>,
    /**
     * Object.fromEntries, but with nice typing
     */
    fromEntries: Object.fromEntries as <K extends string, V>(
        yourObjectEntries: [K, V][]
    ) => Record<K, V>
}
