import React, {FunctionComponent} from 'react';
import {makeStyles} from '@material-ui/core';
import {useLocation} from 'react-router-dom';
import {
    HeroSection,
    FeatureSection,
    ShowcaseSection,
    FeedSection,
    GallerySection,
    FormSection,
    DownloadSection, Metadata, TextSection
} from 'components';
import {IPageProps, LoadingView, ErrorView} from 'views';
import {getPageBySlug, IPage, ISection} from 'api';
import useAxios from 'axios-hooks';

const useStyles = makeStyles((theme) => ({
    page: {
        paddingTop: theme.spacing(6),
        overflow: "hidden",
        [theme.breakpoints.up('md')]: {
            paddingTop: theme.spacing(8),
        },
    }
}));

export const PageView: FunctionComponent<IPageProps> = (props) => {
    const {configuration} = props;
    const classes = useStyles();
    const slug = useLocation().pathname.replace("/", "");
    const [{
        data: page,
        loading: pageLoading,
        error: pageError
    }] = useAxios<IPage>(getPageBySlug(slug.length > 0 ? slug : configuration.homepage.slug));

    if (pageError) {
        return (
            <ErrorView/>
        );
    } else if (pageLoading) {
        return (
            <LoadingView/>
        );
    } else if (!page) {
        return (
            <ErrorView/>
        );
    } else {
        return (
            <div className={classes.page}>
                <Metadata metadata={page.metadata} configuration={configuration} title={page.title}/>
                {page?.sections.map(
                    (section, key) => (
                        <React.Fragment key={key}>{getSection(section)}</React.Fragment>
                    )
                )}
            </div>
        );
    }
}

function getSection(section: ISection) {
    switch (section.__component) {
        case "sections.showcase-section":
            return (<ShowcaseSection section={section}/>);
        case "sections.features-section":
            return (<FeatureSection section={section}/>);
        case "sections.hero-section":
            return (<HeroSection section={section}/>);
        case "sections.feed-section":
            return (<FeedSection section={section}/>);
        case "sections.gallery-section":
            return (<GallerySection section={section}/>);
        case "sections.form-section":
            return (<FormSection section={section}/>);
        case "sections.download-section":
            return (<DownloadSection section={section}/>);
        case "sections.text-section":
            return (<TextSection section={section}/>);
    }
}
