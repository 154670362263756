import { Grid, Typography, Container, makeStyles } from "@material-ui/core";
import { Image, IFeatureSectionProps, ButtonLink } from "components";
import { RichText } from "components/rich-text";
import { FunctionComponent } from "react";

const useStyles = makeStyles((theme) => ({
    content: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
        [theme.breakpoints.up('md')]: {
            paddingTop: theme.spacing(12),
            paddingBottom: theme.spacing(12)
        }
    },
    contentLeft: {
        [theme.breakpoints.up('md')]: {
            paddingLeft: theme.spacing(8)
        }
    },
    contentRight: {
        [theme.breakpoints.up('md')]: {
            paddingRight: theme.spacing(8)
        }
    },
    order2: {
        [theme.breakpoints.up('md')]: {
            order: 2,
        }
    },
    shaped: {
        position: "absolute",
        overflow: "hidden",
        height: "100%",
        width: "100%",
        [theme.breakpoints.up('md')]: {
            width: "50vw",
        }
    },
    shapedLeft: {
        [theme.breakpoints.up('md')]: {
            right: 0,
            clipPath: "ellipse(100% 150% at 0% 50%)",
            shapeOutside: "ellipse(100% 150% at 0% 50%)"
        }
    },
    shapedRight: {
        [theme.breakpoints.up('md')]: {
            left: 0,
            clipPath: "ellipse(100% 150% at 100% 50%)",
            shapeOutside: "ellipse(100% 150% at 100% 50%)"
        }
    },
    shapedContainer: {
        position: "relative",
        height: '150px',
        [theme.breakpoints.up('sm')]: {
            height: "300px",
        },
        [theme.breakpoints.up('md')]: {
            height: "100%",
        }
    },
    positionMiddle: {
        position: "absolute",
        top: "50%",
        transform: "translateY(-50%)",
        minHeight: "100%",
        width: "100%"
    },
    cta: {
        marginTop: theme.spacing(2)
    }
}));

export const FeatureSection: FunctionComponent<IFeatureSectionProps> = (props) => {
    const { section } = props;
    const classes = useStyles();

    let orderClass, shapeClass, contentClass;

    if(section.alignment?.value === "right") {
        orderClass = classes.order2;
        shapeClass = `${classes.shaped} ${classes.shapedRight}`;
        contentClass = `${classes.content} ${classes.contentRight}`;
    } else {
        orderClass = "";
        shapeClass = `${classes.shaped} ${classes.shapedLeft}`;
        contentClass = `${classes.content} ${classes.contentLeft}`;
    }

    return (
        <section>
            <Container>
                <Grid container>
                    <Grid item xs={12} md={6} className={orderClass}>
                        {section.article.image &&
                            <div className={classes.shapedContainer}>
                                <div className={shapeClass}>
                                    <Image image={section.article.image} className={classes.positionMiddle}/>
                                </div>
                            </div>
                        }
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <div className={contentClass}>
                            <Typography variant="h3" gutterBottom color="primary">
                                {section.article.title}
                            </Typography>
                            {section.article.content &&
                                <Typography variant="subtitle1" color="textSecondary">
                                    <RichText content={section.article.content} />
                                </Typography>
                            }
                            {section.article.cta &&
                                <ButtonLink className={classes.cta} link={section.article.cta} />
                            }
                        </div>
                    </Grid>
                </Grid>
            </Container>
        </section>
    );
}
