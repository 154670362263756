import {FunctionComponent} from "react";
import {ITextSectionProps} from "./text-section.types";
import {Container, Typography} from "@material-ui/core";
import {RichText, Section} from "components";


export const TextSection: FunctionComponent<ITextSectionProps> = (props) => {

    const {section} = props;

    return (
        <Section background={section.background} spacing={8}>
            <Container>
                {section.article.title &&
                <Typography variant="h4" align="center" gutterBottom color="primary">
                    {section.article.title}
                </Typography>
                }

                {section.article.content &&
                <Typography component="div">
                    <RichText content={section.article.content}/>
                </Typography>
                }
            </Container>
        </Section>
    );
}
