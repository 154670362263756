import {FunctionComponent} from "react";
import {ImportantIcon, IShowcaseSectionProps, RichText, Section} from "components";
import {Typography, Container, Grid, makeStyles} from "@material-ui/core";
import {getColumnCount} from "lib";

const useStyles = makeStyles((theme) => ({
    subtitle: {
        marginBottom: theme.spacing(4)
    },
    articles: {
        '& p':
            {
                margin: 0
            }
    },
    center: {
        textAlign: 'center',
        [theme.breakpoints.up('md')]:
            {
                textAlign: 'initial'
            }
    }
}));

export const ShowcaseSection: FunctionComponent<IShowcaseSectionProps> = (props) => {

    const {section} = props;
    const classes = useStyles();

    return (
        <Section background={section.background} spacing={8}>
            <Container>
                {section.article.title &&
                <Typography variant="h4" align="center" gutterBottom color="primary">
                    {section.article.title}
                </Typography>
                }

                {section.article.content &&
                <Typography variant="subtitle1" align="center" color="textSecondary" className={classes.subtitle}>
                    <RichText content={section.article.content}/>
                </Typography>
                }

                <Grid container justify="center" spacing={4} className={classes.articles}>
                    {section.articles.map(
                        (article, key) => (
                            <Grid item xs={12} md={getColumnCount(section.articles)} key={key}>
                                <Grid container direction="column" spacing={2} className={classes.center}>

                                    {article.icon &&
                                    <Grid item>
                                        <ImportantIcon icon={article.icon} background={section.background}/>
                                    </Grid>
                                    }

                                    <Grid item>
                                        <Typography variant="h5">
                                            {article.title}
                                        </Typography>
                                    </Grid>

                                    {article.content &&
                                    <Grid item>
                                        <Typography variant="body1" component="div" color="textSecondary">
                                            <RichText content={article.content}/>
                                        </Typography>
                                    </Grid>
                                    }
                                </Grid>
                            </Grid>
                        )
                    )}
                </Grid>
            </Container>
        </Section>
    );
}

