import {FunctionComponent} from "react";
import {IRichTextProps} from "components";
import MarkdownView from 'react-showdown';
import {makeStyles} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    richText: {
        '& > *:first-child, ': {
            marginTop: 0
        },
        '& > *:last-child, ': {
            marginBottom: 0
        },
        '& table': {
            marginLeft: "auto",
            marginRight: "auto",
            [theme.breakpoints.up('md')]: {
                marginLeft: 0,
                marginRight: 0
            }
        },
        '& th': {
            padding: '4px',
            fontWeight: 'inherit',
            [theme.breakpoints.up('md')]: {
                textAlign: 'left'
            }
        },
        '& td': {
            padding: '4px'
        }
    },
}));

export const RichText: FunctionComponent<IRichTextProps> = (props) => {

    const {content} = props;
    const classes = useStyles();

    return (
        <MarkdownView
            className={classes.richText}
            markdown={content}
            options={{tables: true, simpleLineBreaks: true, strikethrough: true, emoji: true}}
        />
    );
}
