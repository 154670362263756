import {FunctionComponent} from "react";
import {IImportantIconProps} from "components";
import {Box, Icon, makeStyles} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    icon: {
        display: "inline-block",
        padding: "17px 18px",
        lineHeight: "1",
        borderRadius: "12px"
    },
    iconPrimary: {
        background: theme.palette.primary.light,
        color: theme.palette.primary.main
    },
    iconSecondary: {
        background: theme.palette.secondary.light,
        color: theme.palette.secondary.main
    },
}));

export const ImportantIcon: FunctionComponent<IImportantIconProps> = (props) => {

    const { icon, background } = props;
    const classes = useStyles();
    const iconClass = background ? `${classes.icon} ${classes.iconSecondary}` : `${classes.icon} ${classes.iconPrimary}`;

    return (
        <Box component="span" className={iconClass}>
            <Icon fontSize="large">{icon}</Icon>
        </Box>
    );
}
