import {makeStyles} from '@material-ui/core/styles';
import {ButtonLink} from 'components';
import {
    Grid,
    AppBar,
    Toolbar,
    Typography,
    Container,
    Drawer,
    List,
    ListItem,
    ListItemText,
    IconButton, Icon, Divider
} from '@material-ui/core';
import {FunctionComponent, useState} from 'react';
import {IHeaderProps} from './header.types';
import {ILink} from 'api';
import {Link} from "react-router-dom";
import {getUrl} from "lib";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
    toolbarTitle: {
        flex: 1,
    },
    alignCenter: {
        display: "flex",
        alignItems: "center"
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
    },
    drawerPaper: {
        width: drawerWidth
    },
    buttonMenu: {
        display: 'none',
        '& > *': {
            paddingLeft: theme.spacing(1)
        },
        [theme.breakpoints.up('md')]: {
            display: 'flex',
        }
    },
    drawerMenu: {
        [theme.breakpoints.up('md')]: {
            display: 'none',
        }
    }
}));

export const Header: FunctionComponent<IHeaderProps> = (props) => {

    const {configuration, navigation} = props;
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const container = window.document.body;

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    const homepageLink: ILink = {
        internalUrl: configuration.homepage,
        title: configuration.companyName
    }

    return (
        <AppBar position="fixed" color="inherit" elevation={6}>
            <Toolbar disableGutters>
                <Container>
                    <Grid container>
                        <Grid item xs={10} md={4} className={classes.alignCenter}>
                            <Typography
                                component="h1"
                                variant="h5"
                                noWrap
                                className={classes.toolbarTitle}
                            >
                                <ButtonLink link={homepageLink} underline="none"/>
                            </Typography>
                        </Grid>

                        <Grid container item xs={2} md={8} justify="flex-end">
                            <div className={classes.buttonMenu}>
                                {navigation.navigation.map(
                                    (item, key) => (
                                        <Grid item key={key}>
                                            <ButtonLink link={item}/>
                                        </Grid>
                                    )
                                )}
                            </div>

                            <div className={classes.drawerMenu}>
                                <IconButton
                                    color="primary"
                                    aria-label="open drawer"
                                    onClick={handleDrawerOpen}
                                    edge="start"
                                >
                                    <Icon fontSize="large">menu</Icon>
                                </IconButton>
                                <Drawer
                                    className={classes.drawer}
                                    container={container}
                                    anchor="right"
                                    open={open}
                                    classes={{
                                        paper: classes.drawerPaper,
                                    }}
                                    onClose={handleDrawerClose}
                                >
                                    <Typography component="div" align="center">
                                        <IconButton
                                            color="primary"
                                            aria-label="close drawer"
                                            onClick={handleDrawerClose}
                                        >
                                            <Icon fontSize="large">chevron_right</Icon>
                                        </IconButton>
                                    </Typography>
                                    <Divider/>
                                    <List>
                                        <ListItem onClick={handleDrawerClose} button component={Link}
                                                  to={configuration.homepage.slug}>
                                            <ListItemText primary={configuration.homepage.title}/>
                                        </ListItem>
                                        {navigation.navigation.map((link, index) => {
                                            const to = getUrl(link, configuration)
                                            const targetAttr = link.externalUrl ? "_blank" : undefined;
                                            let customProps;
                                            if (link.internalUrl) {
                                                customProps = {
                                                    target: targetAttr,
                                                    component: Link,
                                                    to: to
                                                }
                                            } else {
                                                customProps = {
                                                    target: targetAttr,
                                                    component: "a",
                                                    href: to
                                                }
                                            }
                                            return (
                                                <ListItem onClick={handleDrawerClose} key={index} button {...customProps}>
                                                    <ListItemText primary={link.title}/>
                                                </ListItem>
                                            )
                                        })}
                                    </List>
                                </Drawer>
                            </div>
                        </Grid>
                    </Grid>
                </Container>
            </Toolbar>
        </AppBar>
    );
}
