import {FunctionComponent, useContext} from "react";
import {ITranslation} from "api";
import {TransContext} from "app";
import {Checkbox, FormControl, FormControlLabel, FormHelperText} from "@material-ui/core";
import {Controller} from "react-hook-form";
import {ICheckboxInputProps} from "./checkbox-input.types";

export const CheckboxInput: FunctionComponent<ICheckboxInputProps> = (props) => {

    const {title, name, control} = props;
    const trans = useContext<ITranslation>(TransContext).values;

    return (
        <>
            <Controller
                render={({field: {onChange, value, ref}, fieldState: {error}}) => (
                    <FormControl fullWidth error={error !== undefined}>
                        <FormControlLabel
                            label={title}
                            control={
                                <Checkbox
                                    color="primary"
                                    checked={value}
                                    onChange={onChange}
                                    ref={ref}
                                />
                            }
                        />
                        {error && <FormHelperText>{error.message}</FormHelperText>}
                    </FormControl>
                )}
                name={`${name}` as const}
                rules={{
                    required: trans.constraints.required
                }}
                control={control}
                defaultValue={false}
            />
        </>
    );
}
