import {ChangeEvent, FunctionComponent, useContext} from "react";
import {TextField, Typography} from "@material-ui/core";
import {Controller} from "react-hook-form";
import {INumberInputProps} from "./number-input.types";
import {ITranslation} from "api";
import {TransContext} from "app";

export const NumberInput: FunctionComponent<INumberInputProps> = (props) => {

    const {title, name, placeholder, control} = props;
    const trans = useContext<ITranslation>(TransContext).values;

    return (
        <>
            <Typography variant="subtitle2">{title}</Typography>
            <Controller
                render={({field: {onChange, value, ref}, fieldState: {error}}) => (
                    <TextField
                        fullWidth
                        type="number"
                        placeholder={placeholder}
                        variant="outlined"
                        value={input(value)}
                        inputRef={ref}
                        InputProps={{
                            inputProps: {
                                min: 1
                            }
                        }}
                        onChange={(e) => onChange(output(e))}
                        error={error !== undefined}
                        helperText={error?.message}
                    />
                )}
                name={`${name}` as const}
                rules={{
                    required: trans.constraints.required
                }}
                control={control}
            />
        </>
    );
}

function output(event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>): number {
    const output = parseInt(event.target.value, 10);
    return isNaN(output) ? 0 : output;
}

function input(value: number): string {
    return isNaN(value) || value === 0 ? "" : value.toString()
}


