import {FunctionComponent} from 'react';
import {IDownloadSectionProps, ImportantIcon, RichText, Section} from 'components';
import {
    Container,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    makeStyles,
    Typography
} from "@material-ui/core";
import {downloadFile} from "lib";

const useStyles = makeStyles((theme) => ({
    subtitle: {
        marginBottom: theme.spacing(4)
    },
    listItem: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        '&:last-child': {
            border: 0
        }
    },
    listItemAvatar: {
        minWidth: theme.spacing(12)
    }
}));

export const DownloadSection: FunctionComponent<IDownloadSectionProps> = (props) => {

    const {section} = props;
    const classes = useStyles();

    return (
        <Section background={section.background} spacing={8}>
            <Container>
                {section.article.title &&
                <Typography variant="h4" align="center" gutterBottom color="primary">
                    {section.article.title}
                </Typography>
                }

                {section.article.content &&
                <Typography variant="subtitle1" align="center" color="textSecondary" className={classes.subtitle}>
                    <RichText content={section.article.content}/>
                </Typography>
                }
                <List>
                    {section.files.map(
                        (item, key) => (
                            <ListItem key={key} className={classes.listItem} divider button onClick={() => (downloadFile(item.file))}>
                                <ListItemAvatar className={classes.listItemAvatar}>
                                    <ImportantIcon icon="attach_file" background={section.background}/>
                                </ListItemAvatar>
                                <ListItemText primary={item.title} secondary={item.content}/>
                            </ListItem>
                        )
                    )}
                </List>
            </Container>
        </Section>
    )
}
