import {FunctionComponent} from "react";
import {IImageProps} from "components";
import {makeStyles} from "@material-ui/core";
import {getImageUrl} from "lib";

const useStyles = makeStyles((theme) => ({
    root: {
        maxWidth: '100%'
    }
}));

export const Image: FunctionComponent<IImageProps> = (props) => {

    const classes = useStyles();
    const {image, format, className} = props;
    const classString = className ? `${classes.root} ${className}` : classes.root;

    return (
        <>
            <img className={classString} src={getImageUrl(image, format)} alt={image.alternativeText}
                 title={image.caption}/>
        </>
    );
}

