import {Section} from "components";
import {CircularProgress, Container, Typography} from "@material-ui/core";
import React, {FunctionComponent} from "react";
import {ILoadingProps} from "./loading.types";

export const LoadingView: FunctionComponent<ILoadingProps> = (props) => {

    return (
        <Section spacing={16}>
            <Container>
                <Typography component="div" align="center">
                    <CircularProgress/>
                </Typography>
            </Container>
        </Section>
    );
}
