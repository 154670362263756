import {FunctionComponent, useContext} from "react";
import {TextField, Typography} from "@material-ui/core";
import {Controller} from "react-hook-form";
import {ITextInputProps} from "./text-input.types";
import {ITranslation} from "api";
import {TransContext} from "app";

export const TextInput: FunctionComponent<ITextInputProps> = (props) => {

    const {title, name, placeholder, control, rows, optional} = props;
    const trans = useContext<ITranslation>(TransContext).values;

    return (
        <>
            <Typography variant="subtitle2">{title}</Typography>
            <Controller
                render={({field: {onChange, value, ref}, fieldState: {error}}) => (
                    <TextField
                        fullWidth
                        placeholder={placeholder}
                        variant="outlined"
                        value={value}
                        ref={ref}
                        onChange={onChange}
                        error={error !== undefined}
                        helperText={error?.message}
                        multiline={rows !== undefined}
                        rows={rows}
                    />
                )}
                name={`${name}` as const}
                rules={
                    optional === true ? {} : { required: trans.constraints.required }
                }
                control={control}
                defaultValue={""}
            />
        </>
    );
}


