import { FunctionComponent } from "react";
import { ISectionProps } from "components";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    section: (props: ISectionProps) => ({
        paddingTop: theme.spacing(props.spacing === undefined ? 0 : props.spacing),
        paddingBottom: theme.spacing(props.spacing === undefined ? 0 : props.spacing),
        background: props.background?.value === "full" || props.background?.value === "rounded" ? theme.palette.primary.light : undefined,
        borderBottomRightRadius: props.background?.value === "rounded" ? "100%" : undefined
    })
}));

export const Section: FunctionComponent<ISectionProps> = (props) => {
    const { children, className } = props;
    const classes = useStyles(props);

    const sectionClass = className ? `${props.className} ${classes.section}` : classes.section;

    return (
        <section className={sectionClass}>
            {children}
        </section>
    );
}

