import {Typography, Paper, Container, Grid} from "@material-ui/core";
import {makeStyles} from '@material-ui/core/styles';
import {IHeroSectionProps, Image} from "components";
import {FunctionComponent} from "react";
import {getImageUrl} from 'lib';
import {ButtonLink} from "components/button-link";
import {RichText} from "components/rich-text";

const useStyles = makeStyles((theme) => ({
    heroSectionImage: {
        position: 'relative',
        backgroundColor: theme.palette.grey[800],
        color: theme.palette.common.white,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
    },
    heroSectionContent: {
        position: 'relative',
        paddingTop: theme.spacing(8),
        paddingBottom: theme.spacing(8),
        [theme.breakpoints.up('md')]: {
            paddingTop: theme.spacing(24),
            paddingBottom: theme.spacing(24),
        }
    },
    heroSectionOverlay: {
        position: 'absolute',
        top: 0,
        bottom: 0,
        right: 0,
        left: 0,
        backgroundColor: 'rgba(0,0,0,.3)',
    },
    cta: {
        marginTop: theme.spacing(2)
    }
}));

export const HeroSection: FunctionComponent<IHeroSectionProps> = (props) => {
    const {section} = props;

    const classes = useStyles();

    const textContent = (
        <Container className={classes.heroSectionContent}>
            <Grid container>
                <Grid item xs={12} md={8}>
                    <Typography variant="h1" gutterBottom color="secondary">{section.article.title}</Typography>
                    {section.article.content &&
                    <Typography variant="subtitle1" color="inherit">
                        <RichText content={section.article.content}/>
                    </Typography>
                    }
                    {section.article.cta  &&
                    <ButtonLink className={classes.cta} link={section.article.cta}/>
                    }
                </Grid>
            </Grid>
        </Container>
    );

    if (section.article.image) {
        return (
            <section>
                <Paper className={classes.heroSectionImage}
                       style={{backgroundImage: `url(${getImageUrl(section.article.image)})`}}>
                    <div className={classes.heroSectionOverlay}/>
                    <div style={{display: "none"}}>
                        <Image image={section.article.image}/>
                    </div>
                    {textContent}
                </Paper>
            </section>
        );
    } else {
        return (
            <section>
                <Paper className={classes.heroSectionImage}>
                    {textContent}
                </Paper>
            </section>
        );
    }

}
