import {FunctionComponent, useContext} from "react";
import {FormControl, FormHelperText, MenuItem, Select, Typography} from "@material-ui/core";
import {Controller} from "react-hook-form";
import {ISelectInputProps} from "./select-input.types";
import {ITranslation} from "api";
import {TransContext} from "app";

export const SelectInput: FunctionComponent<ISelectInputProps> = (props) => {

    const {title, name, control, values} = props;
    const trans = useContext<ITranslation>(TransContext).values;

    return (
        <>
            <Typography variant="subtitle2">{title}</Typography>
            <Controller
                render={({field: {onChange, value, ref}, fieldState: {error}}) => (
                    <FormControl fullWidth error={error !== undefined}>
                        <Select
                            variant="outlined"
                            inputRef={ref}
                            value={value}
                            onChange={onChange}
                        >
                            {values.map((item, key) => {
                                return (
                                    <MenuItem key={key} value={item.value}>{item.label}</MenuItem>
                                );
                            })}
                        </Select>
                        {error && <FormHelperText>{error.message}</FormHelperText>}
                    </FormControl>
                )}
                name={`${name}` as const}
                rules={{
                    required: trans.constraints.required
                }}
                control={control}
                defaultValue={values[0].value}
            />
        </>
    );
}


