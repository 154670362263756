import React, {FunctionComponent} from "react";
import {IMetadataProps} from "./metadata.types";
import {getImageUrl} from "lib";
import {Helmet} from "react-helmet-async";

export const Metadata: FunctionComponent<IMetadataProps> = (props) => {

    const {configuration, metadata, title} = props;
    const titleText = metadata?.metaTitle ? `${metadata.metaTitle} | ${configuration.companyName}` : title ? `${title} | ${configuration.companyName}` : configuration.companyName;

    return (
        <>
            <Helmet>
                <meta name="twitter:card" content="summary_large_image"/>
                <meta property="og:type" content="website"/>
                <meta property="og:url" content={process.env.PUBLIC_URL}/>
            </Helmet>

            {titleText &&
            <Helmet>
                <title>{titleText}</title>
                <meta itemProp="name" content={titleText}/>
                <meta property="og:title" content={titleText}/>
                <meta name="twitter:title" content={titleText}/>
            </Helmet>
            }

            {configuration.favicon &&
            <Helmet>
                <link rel="apple-touch-icon" href={getImageUrl(configuration.favicon, "thumbnail")}/>
                <link rel="icon" href={getImageUrl(configuration.favicon, "thumbnail")}/>
            </Helmet>
            }

            {metadata?.metaDescription &&
            <Helmet>
                <meta name="description"
                      content={metadata.metaDescription}/>
                <meta itemProp="description"
                      content={metadata.metaDescription}/>
                <meta property="og:description"
                      content={metadata.metaDescription}/>
                <meta name="twitter:description"
                      content={metadata.metaDescription}/>
            </Helmet>
            }

            {metadata?.metaKeywords &&
            <Helmet>
                <meta name="keywords"
                      content={metadata.metaKeywords}/>
            </Helmet>
            }

            {metadata?.metaImage &&
            <Helmet>
                <meta itemProp="image" content={getImageUrl(metadata.metaImage, "medium")}/>
                <meta property="og:image" content={getImageUrl(metadata.metaImage, "medium")}/>
                <meta name="twitter:image" content={getImageUrl(metadata.metaImage, "medium")}/>
            </Helmet>
            }

        </>
    );
}
