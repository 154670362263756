import {FunctionComponent, useContext} from "react";
import {IFooterProps} from "./footer.types";
import {Container, makeStyles, Grid, Typography, Box, Link, Divider} from "@material-ui/core";
import {Section, ButtonLink} from "components";
import {ITranslation} from "../../api";
import {TransContext} from "../../app";

const useStyles = makeStyles((theme) => ({
    section: {
        background: theme.palette.primary.dark,
        color: theme.palette.grey[400],
        paddingBottom: `${theme.spacing(4)}px!important`
    },
    contrast: {
        color: theme.palette.primary.contrastText
    },
    greyText: {
        color: theme.palette.grey[400]
    },
    divider: {
        height: "2px",
        backgroundColor: "rgba(255, 255, 255, 0.12)",
        margin: theme.spacing(4)
    },
    navigation: {
        marginTop: theme.spacing(2),
        textAlign: "right",
        [theme.breakpoints.up('md')]: {
            marginTop: 0,
            textAlign: "initial"
        }
    },
    contact: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    }
}));


export const Footer: FunctionComponent<IFooterProps> = (props) => {

    const classes = useStyles();
    const {configuration, navigation} = props;
    const trans = useContext<ITranslation>(TransContext).values;

    return (
        <Section spacing={8} className={classes.section}>
            <Container>
                <Grid container>
                    <Grid item xs={12} md={4}>
                        <Typography variant="h5" className={classes.contrast}>
                            {configuration.companyName}
                        </Typography>
                        {configuration.contact &&
                        <>
                            <Box className={classes.contact}>
                                {configuration.contact.street &&
                                <Typography variant="body1">
                                    {configuration.contact.street}
                                </Typography>
                                }

                                {(configuration.contact.zip || configuration.contact.city) &&
                                <Typography variant="body1">
                                    {configuration.contact.zip} {configuration.contact.city}
                                </Typography>
                                }

                                {configuration.contact.country &&
                                <Typography variant="body1">
                                    {configuration.contact.country}
                                </Typography>
                                }
                            </Box>

                            <Box className={classes.contact}>
                                {configuration.contact.companyId &&
                                <Typography variant="body1">
                                    {trans.companyId}: {configuration.contact.companyId}
                                </Typography>
                                }

                                {configuration.contact.taxId &&
                                <Typography variant="body1">
                                    {trans.taxId}: {configuration.contact.taxId}
                                </Typography>
                                }
                            </Box>

                            {configuration.contact.contacts.map(
                                (item, key) => (
                                    <Box key={key} className={classes.contact}>
                                        <Typography variant="subtitle1" className={classes.contrast}>
                                            {item.title}
                                        </Typography>
                                        <Typography variant="body1">
                                            <Link href={`mailto:${item.email}`}
                                                  className={classes.greyText}>{item.email}</Link>
                                        </Typography>
                                        <Typography variant="body1">
                                            <Link href={`tel:${item.phone}`}
                                                  className={classes.greyText}>{item.phone}</Link>
                                        </Typography>
                                    </Box>
                                )
                            )}
                        </>
                        }

                    </Grid>
                    <Grid item xs={12} md={8}>
                        <Grid container spacing={4} justify="flex-end" className={classes.navigation}>
                            {navigation.navigation.map(
                                (item, key) => (
                                    <Grid item xs={12} md={"auto"} key={key}>
                                        <Typography variant="subtitle1">
                                            <ButtonLink link={item} overrideType="link" className={classes.contrast}/>
                                        </Typography>
                                    </Grid>
                                )
                            )}
                        </Grid>
                    </Grid>
                </Grid>
                <Divider className={classes.divider}/>
                <Typography variant="body2" align="center">
                    Copyright © 2020 - {new Date().getFullYear()} {configuration.companyName}
                </Typography>
            </Container>
        </Section>
    );
}
