import {FunctionComponent, useContext} from "react";
import {IDatetimeInputProps} from "./datetime-input.types";
import {Typography} from "@material-ui/core";
import {Controller} from "react-hook-form";
import {DatePicker} from "@material-ui/pickers";
import {ITranslation} from "api";
import {TransContext} from "app";

export const DatetimeInput: FunctionComponent<IDatetimeInputProps> = (props) => {

    const {title, name, placeholder, control} = props;
    const trans = useContext<ITranslation>(TransContext).values;

    return (
        <>
            <Typography variant="subtitle2">{title}</Typography>
            <Controller
                render={({field: {onChange, value, ref}, fieldState: {error}}) => (
                    <DatePicker
                        fullWidth
                        inputVariant="outlined"
                        placeholder={placeholder}
                        value={value}
                        disableFuture
                        openTo="year"
                        format="DD.MM.yyyy"
                        views={["year", "month", "date"]}
                        inputRef={ref}
                        onChange={onChange}
                        error={error !== undefined}
                        helperText={error?.message}
                    />
                )}
                name={`${name}` as const}
                rules={{
                    required: trans.constraints.required
                }}
                control={control}
                defaultValue={null}
            />
        </>
    );

}
