import {FunctionComponent} from "react";
import {Section, RichText, Image} from "components";
import {IGallerySectionProps} from "./gallery-section.types";
import {
    Typography,
    Container,
    makeStyles,
    GridList,
    GridListTile,
    useMediaQuery,
    useTheme,
    Box
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    subtitle: {
        marginBottom: theme.spacing(4)
    },
    tile: {
        display: "flex",
        height: "100%",
        flexFlow: "row",
        justifyContent: "center",
        alignContent: "center",
        [theme.breakpoints.up('md')]: {
            flexFlow: "column"
        }
    }
}));

export const GallerySection: FunctionComponent<IGallerySectionProps> = (props) => {

    const {section} = props;
    const classes = useStyles();
    const theme = useTheme();
    const isDesktop = useMediaQuery(theme.breakpoints.up('md'));

    return (
        <Section background={section.background} spacing={8}>
            <Container>
                {section.article.title &&
                <Typography variant="h4" align="center" gutterBottom color="primary">
                    {section.article.title}
                </Typography>
                }

                {section.article.content &&
                <Typography variant="subtitle1" align="center" color="textSecondary" className={classes.subtitle}>
                    <RichText content={section.article.content}/>
                </Typography>
                }

                <GridList spacing={24} cols={isDesktop ? 5 : 1} cellHeight='auto'>
                    {section.images.map(
                        (image, key) => (
                            <GridListTile key={key}>
                                <Box className={classes.tile}>
                                    <Image image={image} format="thumbnail"/>
                                </Box>
                            </GridListTile>
                        )
                    )}
                </GridList>
            </Container>
        </Section>
    )
}
