import {Button, Link as MuiLink} from "@material-ui/core";
import {ConfContext} from "app";
import {FunctionComponent, useContext} from "react";
import {Link} from "react-router-dom";
import {IButtonProps} from "./button-link.types";
import {IConfiguration} from "api";
import * as React from "react";
import {getUrl} from "lib";

export const ButtonLink: FunctionComponent<IButtonProps> = (props) => {

    const {link, overrideType, className, underline, newTab} = props;
    const configuration = useContext<IConfiguration>(ConfContext);
    const to = getUrl(link, configuration)
    const targetAttr = link.externalUrl || newTab ? "_blank" : undefined;

    const commonProps = {
        className: className,
        color: link.color?.value,
        target: targetAttr
    }
    let customProps;
    if (link.internalUrl) {
        customProps = {
            ...commonProps,
            component: Link,
            to: to
        }
    } else {
        customProps = {
            ...commonProps,
            component: "a",
            href: to
        }
    }

    let buttonType = link.type?.value;

    if (overrideType) {
        buttonType = overrideType;
    }

    if (buttonType && buttonType !== "link") {
        const variant: "text" | "outlined" | "contained" = buttonType;

        return (
            <Button
                variant={variant}
                {...customProps}
            >
                {link.title}
            </Button>
        );
    } else {
        return (
            <MuiLink
                underline={underline}
                {...customProps}
            >
                {link.title}
            </MuiLink>
        );
    }
}
