const cmsRoot = process.env.REACT_APP_CMS_HOST;
const beRoot = process.env.REACT_APP_BE_HOST;

export const apiRoutes = {
    cms: {
        root: cmsRoot,
        page: cmsRoot + "/page",
        navigation: cmsRoot + "/navigation",
        configuration: cmsRoot + "/configuration",
        feed: cmsRoot + "/feed",
        translation: cmsRoot + "/translation"
    },
    be: {
        root: beRoot,
        prescription: beRoot + "/prescription",
        contact: beRoot + "/contact"
    }
};
