import { apiRoutes } from "config";
import { AxiosRequestConfig } from "axios";

export const getFeedList = ():AxiosRequestConfig => {
    return {
        url: apiRoutes.cms.feed,
        params: {
            _limit: 4,
            _sort: "id:DESC"
        }
    }
};
